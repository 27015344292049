import React from 'react';
import Layout from '../components/Layout';

const Pianomoving = () => (
  <Layout>
  <article id="main">
  <header>
  <h2> Moving your piano from Carlsbad</h2>
  <p>Ray Charles moved on the keys, but he didn't move pianos</p>
  </header>

  <section className="wrapper style5">
    <div className='inner'>
    <section>
    <header>
      <h4>Moving pianos is not as hard as moving melodies</h4>
      <p>So let us do the easy stuff and handle the piano moving so you can rest up and dream up some more jams.</p>
    </header>

    <p>Whatever kind of keys you have, we can move them. Pianos we have moved include the classic Fender Rhodes, Steinway baby grand pianos, Bluthner upright pianos, the Bechstein concert 8, the Bechstein Elegance 124, the Bluthner Model S, the Bosendorefer Grand Upright 130, the Yamaha U3 upright piano, Yamaha U1 upright piano, the Yamaha Arius YDP162R digital upright pianos, the Schiller Special Edition upright piano, and most recently an amazing August Forster 134k.

    </p>
    <h4> Get a big discount on your move if you can play:</h4>
    <ul>
      <li>Nocturne Op.9 No. 2</li>
      <li>September</li>
      <li>Superstition</li>
      <li>Chameleon</li>
      <li>Chopsticks :P</li>

      </ul>
      </section>
      </div>
      </section>
      </article>
      </Layout>

)
export default Pianomoving;
